"use client";

import { SignoutReason } from "@/constants/signout-reasons";
import { Modal } from "@costar/theme-ui";
import { useRouter, useSearchParams } from "next/navigation";
import { useState, useMemo, useCallback, useEffect } from "react";
import { generatedResources } from "@/app/i18n/src";
import { BIMMO_CONTACT_PHONENUMBER } from "@/app/(root)/(news)/constants";

export const BumpoutModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const reason = useSearchParams()?.get("r");
  const router = useRouter();

  useEffect(() => {
    if (reason) {
      setOpen(true);
    }
  }, [reason]);

  const closeModal = useCallback(() => {
    setOpen(false);
    router.replace("/");
  }, [router]);

  const resources = useMemo(() => generatedResources["fr-FR"], []);
  const bumpOutMessages = useMemo(() => {
    switch (reason) {
      case SignoutReason.DuplicateSession:
        return {
          title: resources.sharingViolationTitle(),
          description: resources.sharingViolationDescription(),
        };
      case SignoutReason.TimedOutMaxDuration:
        return {
          title: resources.timeOutTitle(),
          description: resources.timeOutDescription(),
        };
      case SignoutReason.GlobalTimeout:
        return {
          title: resources.globalTimeOutTitle(),
          description: resources.timeOutDescription(),
        };
      case SignoutReason.UnAuthorized:
        return {
          title: resources.unAuthorizedTitle(),
          description: resources.unAuthorizedDescription(),
        };
      case SignoutReason.CompanyOnHold:
        return {
          title: resources.CompanyOnHoldTitle(),
          description: resources.CompanyOnHoldDescription({
            phoneNum: BIMMO_CONTACT_PHONENUMBER,
          }),
        };
      default:
        return {
          title: resources.defaultTitle(),
          description: resources.defaultMessage({
            phoneNum: BIMMO_CONTACT_PHONENUMBER,
          }),
        };
    }
  }, [reason, resources]);
  const okayGotItButtonText = useMemo(
    () => resources.okayGotItButton(),
    [resources]
  );

  return (
    <Modal open={open} onBackdropClick={closeModal}>
      <div className="min-h-[200px] w-3/4 border border-[rgba(0,0,0,0.2)] bg-white p-8 shadow-[0_5px_15px_rgba(0,0,0,.5)] sm:h-[200px] lg:w-[600px]">
        <div className="flex h-full flex-col max-sm:content-center max-sm:gap-4 sm:justify-between">
          <p className="text-24 text-primary">{bumpOutMessages.title}</p>
          <p className="text-14">{bumpOutMessages.description}</p>
          <button
            className="bg-primary px-5 py-1.5 text-white sm:self-end"
            onClick={closeModal}
          >
            {okayGotItButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
